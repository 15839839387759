import React from 'react';
import teamPhoto from '../../assets/images/Janelle and Melissa Brick Wall_2.jpg';
import InfoCardGroup from '../InfoCardGroup';
import Page from '../Page';
import Notice from '../Notice';
import './style.css';

export const Homepage = () => {
  return (
    <Page id='homepage' className='landing'>
      <Notice />
      <div className='background__banner'>
        <div className='banner__overlay'>
          <h3>
            The definition of interconnection is to be meaningfully related, or
            simply, to connect with one another.
            <br />
            <span className='bold-mission'>
              Our mission is to foster interconnections through collaborative
              therapy.
            </span>
          </h3>
          {/* <a
            href="https://interconnectionscounseling.mytherabook.com/appointments/new"
            className="banner__call-to-action">
            Schedule Now
          </a> */}
        </div>
      </div>
      <div className='banner'>
        <img
          className='banner__img'
          src={teamPhoto}
          alt='Melissa Pizzo and Janelle Kramer'
        />
      </div>
      <InfoCardGroup style={{ position: 'relative', bottom: '15px' }} />
    </Page>
  );
};

export default Homepage;
