import React from 'react';

export const LocationMap = ({innerWidth}) => {
  if (innerWidth >= 1000) {
    return (
      <iframe
        title="interconnections-office-location-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3066.2782128910153!2d-105.08432568475736!3d39.778311402142215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b87f5306f8d11%3A0x205eb8737f17fa78!2sInterconnections+Counseling%2C+LLC!5e0!3m2!1sen!2sus!4v1541880909816"
        width="650"
        height="450"
        frameBorder="0"
        style={{border: 0, margin: 'auto', display: 'block'}}
        allowFullScreen
      />
    );
  }
  if (innerWidth > 500) {
    return (
      <iframe
        title="medium-office-location-map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12265.11358263279!2d-105.082137!3d39.7783073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x205eb8737f17fa78!2sInterconnections+Counseling%2C+LLC!5e0!3m2!1sen!2sus!4v1542143069407"
        width="400"
        height="300"
        frameBorder="0"
        style={{border: 0, margin: 'auto', display: 'block'}}
        allowFullScreen
      />
    );
  }
  if (innerWidth > 300) {
    return (
      <iframe
        title="medium-office-location-map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12265.11358263279!2d-105.082137!3d39.7783073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x205eb8737f17fa78!2sInterconnections+Counseling%2C+LLC!5e0!3m2!1sen!2sus!4v1542143069407"
        width="300"
        height="300"
        frameBorder="0"
        style={{border: 0, margin: 'auto', display: 'block'}}
        allowFullScreen
      />
    );
  }
  return null;
};

export default LocationMap;
