import React from 'react';
import { Table } from 'reactstrap';

export const ContactTable = () => (
  <Table borderless hover className="table-sm">
    <tbody>
      <tr>
        <th scope="row">Phone:</th>
        <td>
          <a
            href="tel:+19708232392"
            aria-label="link to call 9 7 0 8 2 3 2 3 9 2">
            970-823-2392
          </a>{' '}
          Melissa
          <br />
          <a
            href="tel:+17207731104"
            aria-label="link to call 7 2 1 7 7 3 1 1 0 4">
            720-773-1104
          </a>{' '}
          Janelle
        </td>
      </tr>
      <tr>
        <th scope="row">Email:</th>
        <td>
          <a
            href="mailto:info@interconnectionscounseling.com"
            aria-label="link to email info at interconnections counseling dot com">
            info@interconnectionscounseling.com
          </a>
        </td>
      </tr>
      <tr>
        <th scope="row">Address:</th>
        <td>
          <a
            href="https://goo.gl/maps/o88HUZYUziQ2"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="link to google maps directions to interconnections counseling">
            4485 Wadsworth Blvd. Ste 203
            <br />
            Wheat Ridge, CO 80033
          </a>
        </td>
      </tr>
    </tbody>
  </Table>
);

export default ContactTable;
