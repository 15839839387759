import React from 'react';
import './style.css';
import profiles from '../../profiles';
import Profile from '../Profile';
import Page from '../Page';

export const About = () => {
  const profileViews = profiles.map((profile, index) => (
    <Profile
      key={`${profile.name}-profile`}
      {...profile}
      right={index % 2 !== 0}
    />
  ));

  return (
    <Page id="about" className="about" title="About" description="About Us">
      <h2 className="display-5 text-white">About Us</h2>
      <section className="about__profiles">{profileViews}</section>
    </Page>
  );
};

export default About;
