import React from 'react';
import './style.css';
import Page from '../Page';

export const Fees = () => {
  return (
    <Page
      id="fees"
      className="fees"
      title="Fees"
      description="Our fee structure.">
      <h2>Fees</h2>
      <p>
        Aside from insurances, our practice offers competitive out-of-pocket
        rates. Our rate is $145 for a 60 minute session and $185 for a 75 minute
        session.
      </p>
      <p>
        We provide a free 30 minute consultation session. The purpose of this
        consultation is to discuss treatment needs and goals and to ensure both
        client and therapist agree that we are a therapeutic fit. This
        consultation session can take place either in person or over the phone.
      </p>
      <p>
        Sliding scale fees are available and can be discussed during the initial
        30 minute consultation.
      </p>
    </Page>
  );
};

export default Fees;
