import React from 'react';
import './style.css';
import psychVerified from '../../assets/images/psychTodayVerified.png';

export const Profile = ({
  name,
  image,
  phone,
  bio,
  right,
  psychTodayProfile
}) => {
  const imgClass = right ? 'profile__img--right' : '';
  const headingClass = right ? 'profile__heading--text-right' : '';

  return (
    <article className="profile clearfix">
      <img
        src={image.src}
        alt={image.alt}
        className={`profile__img ${imgClass}`}
      />
      <h3 className={`profile__heading ${headingClass}`}>{name}</h3>
      {bio.map((paragraph, index) => (
        <p key={`${name}-bio-${index}`} className="profile__body">
          {paragraph}
        </p>
      ))}
      <p className="profile__body">
        Give me a call today at{' '}
        <a
          className="text-dark"
          href={`tel:${phone.replace(/-/g, '')}`}
          aria-label={phone
            .split('-')
            .map(x => x.split('').join(' '))
            .join('. ')}
        >
          {phone}
        </a>
        .
      </p>
      <a
        href={psychTodayProfile}
        className="sx-verified-seal"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={psychVerified}
          alt="psychology today verified"
          style={{ width: '250px', float: 'right', paddingRight: '1rem' }}
        />
      </a>
    </article>
  );
};

export default Profile;
