import React from 'react';
import LocationMap from '../Map';
import ContactTable from '../ContactTable';

export const MapGroup = ({ innerWidth }) => {
  const flexDirection = innerWidth >= 770 ? 'flex-row' : 'flex-column-reverse';
  const alignItems = innerWidth >= 770 ? 'start' : 'center';
  const textSize = innerWidth < 515 ? 'small' : '';

  return (
    <section
      className={`d-flex justify-content-around align-items-${alignItems} ${flexDirection} ${textSize} p-3 bg-white`}>
      <div className="mt-2">
        <LocationMap innerWidth={innerWidth} />
      </div>
      <div className="mt-2">
        <ContactTable />
      </div>
    </section>
  );
};

export default MapGroup;
