import React from 'react';
import './style.css';
import { Table } from 'reactstrap';
import Page from '../Page';

export const Resources = ({ innerWidth }) => {
  const textSize = innerWidth <= 515 ? 'small' : '';
  return (
    <Page
      id="resources"
      title="Resources"
      className="resources"
      description="Resources in our community.">
      <h3>Community Resources</h3>
      <p>
        Below is a list of resources in our community. In case of an emergency,
        please call 911.
      </p>
      <Table className={`table-sm ${textSize}`}>
        <tbody>
          <tr>
            <th scope="row" className="text-nowrap">
              Colorado 2-1-1
            </th>
            <td>
              Dial <a href="tel:211">2-1-1</a> in Colorado to locate services
              near you such as housing and shelter, food assistance, legal,
              health, child assistance, and much more.{' '}
              <a
                href="https://211colorado.communityos.org/cms/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label="link to colorado 2 1 1 homepage">
                https://211colorado.communityos.org/cms/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">National Suicide Prevention Lifeline</th>
            <td>
              <a href="tel:18002738255">1-800-273-8255</a> The Lifeline provides
              24/7, free and confidential support for people in distress,
              prevention and crisis resources for you or your loved ones, and
              best practices for professionals.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://suicidepreventionlifeline.org/"
                aria-label="link to national suicide prevention lifeline website">
                https://suicidepreventionlifeline.org/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Child Health Plan Plus (CHP+)</th>
            <td>
              Child Health Plan Plus (CHP+) is public low-cost health insurance
              for certain children and pregnant women. It is for people who earn
              too much to qualify for Health First Colorado (Colorado's Medicaid
              Program), but not enough to pay for private health insurance.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.colorado.gov/pacific/hcpf/child-health-plan-plus"
                aria-label="link to Colorado's Child Health Plan Plus program website">
                https://www.colorado.gov/pacific/hcpf/child-health-plan-plus
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Jefferson County Human Services</th>
            <td>
              Through early childhood education, adult and child protection, job
              training, food assistance, Medicaid, and other programs, Human
              Services helps people build better, safer lives.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.jeffco.us/Human-Services"
                aria-label="link to Jefferson County Human Services website">
                https://www.jeffco.us/Human-Services
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Denver Human Services</th>
            <td>
              Assistance programs including food, cash and medical benefits,
              child care, child support, and general assistance are provided to
              eligible Denver residents in financial need. Other assistance
              programs include Employment First and Veteran Services, which work
              with Denver residents and veterans on job placement and retention.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.denvergov.org/content/denvergov/en/denver-human-services.html"
                aria-label="link to Denver Human Services website">
                https://www.denvergov.org/content/denvergov/en/denver-human-services.html
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
      <h3>Colorado Medicaid Resources</h3>
      <Table className={`table-sm ${textSize}`}>
        <tbody>
          <tr>
            <th scope="row">Health First Colorado</th>
            <td>
              Colorado's Medicaid Program.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.healthfirstcolorado.com/"
                aria-label="link to Health First Colorado website">
                https://www.healthfirstcolorado.com
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Colorado Community Health Alliance</th>
            <td>
              Regional Medicaid organization for Boulder, Broomfield, Clear
              Creek, El Paso, Gilpin, Jefferson, Park, and Teller counties.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.cchacares.com/"
                aria-label="link to Colorado Communnity Health Alliance website">
                https://www.cchacares.com/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Colorado Access</th>
            <td>
              Regional Medicaid organization for Adams, Arapahoe, Denver,
              Douglas, and Elbert counties.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="http://www.coaccess.com/"
                aria-label="link to Colorado Access website">
                http://www.coaccess.com/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Health Colorado, Inc.</th>
            <td>
              Regional Medicaid organization for Alamosa, Baca, Bent, Chaffee,
              Conejos, Costilla, Crowley, Custer, Fremont, Huerfano, Kiowa,
              Lake, Las Animas, Mineral, Otero, Prowers, Pueblo, Rio Grande, and
              Saguache counties.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.healthcoloradorae.com/"
                aria-label="link to Health Colorado, Inc. website">
                https://www.healthcoloradorae.com/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Northeast Health Partners</th>
            <td>
              Regional Medicaid organization for Cheyenne, Kit Carson, Lincoln,
              Logan, Morgan, Phillips, Sedgwick, Washington, Weld, and Yuma
              counties.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.northeasthealthpartners.org/"
                aria-label="link to Northeast Health Partners website">
                https://www.northeasthealthpartners.org/
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Rocky Mountain Health Plans</th>
            <td>
              Regional Medicaid organization for Archuleta, Delta, Dolores,
              Eagle, Garfield, Grand, Gunnison, Hinsdale, Jackson, La Plata,
              Larimer, Mesa, Moffat, Montezuma, Montrose, Ouray, Pitkin, Rio
              Blanco, Routt, San Juan, San Miguel, and Summit counties.{' '}
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://www.rmhp.org/"
                aria-label="link to Rocky Mountain Health Plans website">
                https://www.rmhp.org/
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    </Page>
  );
};

export default Resources;
