import React from 'react';
import { Card, CardTitle, CardSubtitle } from 'reactstrap';
import { string } from 'prop-types';
import './style.css';

export const InfoCard = ({ title, subtitle }) => (
  <Card body className="justify-content-start rounded info-card mb-4">
    <CardTitle>{title}</CardTitle>
    <CardSubtitle>{subtitle}</CardSubtitle>
  </Card>
);

InfoCard.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
};

export default InfoCard;
