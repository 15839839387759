import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from 'reactstrap';
import Page from '../Page';
import './style.css';

export const Insurances = () => {
  return (
    <Page
      id="insurances"
      title="Insurances"
      className="insurances"
      description="Insurances that our practice accepts"
    >
      <h2>Insurances</h2>
      <p>Here is a list of the insurances that we currently accept.</p>
      <ListGroup>
        <ListGroupItem>
          <ListGroupItemHeading>Colorado Access</ListGroupItemHeading>
          <ListGroupItemText>
            See our <NavLink to="/resources">resources page</NavLink> for links
            to more information about Medicaid in Colorado.
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemHeading>
            Colorado Community Health Alliance (CCHA)
          </ListGroupItemHeading>
          <ListGroupItemText>
            See our <NavLink to="/resources">resources page</NavLink> for links
            to more information about Medicaid in Colorado.
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemHeading className="m-0">
            Child Health Plan Plus (CHP+)
          </ListGroupItemHeading>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemHeading className="m-0">Cigna</ListGroupItemHeading>
        </ListGroupItem>
        <ListGroupItem>
          <ListGroupItemHeading className="m-0">
            United Healthcare (Optum)
          </ListGroupItemHeading>
        </ListGroupItem>
      </ListGroup>
      <p>We are actively working to add additional insurance providers.</p>
      <p>
        In the meantime, please give us a call or see our{' '}
        <NavLink to="/fees">fees</NavLink> page for information about our
        private payer rates.
      </p>
    </Page>
  );
};

export default Insurances;
