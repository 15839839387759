import React from 'react';
import MapGroup from '../MapGroup';
import {Container, ListGroup, ListGroupItem} from 'reactstrap';
import Page from '../Page';
import './style.css';

export const Contact = ({innerWidth}) => {
  return (
    <Page
      id="contact"
      title="Contact"
      className="pt-5 contact"
      description="Contact and scheduling information for Interconnections Counseling">
      <Container>
        <h2>Scheduling</h2>
        <p>
          We are always considering new patients. Please give us a call to set
          up a new patient consultation or to schedule your next appointment.
          {/* <a */}
          {/*   className="appt-btn" */}
          {/*   href="https://interconnectionscounseling.mytherabook.com/account/signup" */}
          {/*   target="_blank" */}
          {/*   rel="noopener noreferrer" */}
          {/*   aria-label="new patient login button"> */}
          {/*   NEW PATIENT LOGIN */}
          {/* </a> */}
        </p>
        {/* <p>
          Returning patients - to access your patient profile or view the
          scheduling portal, please click on the login button below.
          <a
            className="appt-btn"
            href="https://interconnectionscounseling.mytherabook.com/home/login"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="returing patient login button">
            RETURNING PATIENT LOGIN
          </a>
        </p> */}
        <p>
          If you need to reschedule, please contact your counselor directly by
          phone.
        </p>
        <p>
          For all other inquiries, please{' '}
          <a
            href="mailto:info@interconnectionscounseling.com"
            aria-label="link to send email to interconnections counseling">
            send us an email.
          </a>
        </p>
      </Container>
      <MapGroup innerWidth={innerWidth} />
      <Container>
        <h2>Parking</h2>
        <p>
          There is parking in front of the building as well as along the left
          side and around the back.
          <br />
          <br />
          If you park in front of the building:
        </p>
        <ListGroup className="parking">
          <ListGroupItem>
            Go to the right from the main entrance and then turn left at the
            vending machines.
          </ListGroupItem>
          <ListGroupItem>
            Follow that corridor to the end of the hallway where you will find a
            staircase on the left, just past the elevator.
          </ListGroupItem>
          <ListGroupItem>
            The office is on the second floor and is the second door on the left
            when you reach the second floor from the stairs and is directly in
            front of the elevator.
          </ListGroupItem>
        </ListGroup>
      </Container>
      <Container>
        <p className="mt-3">If you park in the back of the building:</p>
        <ListGroup className="parking">
          <ListGroupItem>
            There is only one door, it is open to the public.
          </ListGroupItem>
          <ListGroupItem>
            The staircase is just to the right of the door. The elevator is on
            the right a few feet from the door.
          </ListGroupItem>
          <ListGroupItem>
            The office is the second door on the left when you reach the second
            floor from the stairs and is directly in front of the elevator.
          </ListGroupItem>
        </ListGroup>
      </Container>
    </Page>
  );
};

export default Contact;
