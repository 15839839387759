import melissaHeadshot from './assets/images/Melissa Brick Wall_1.jpg';
import janelleHeadshot from './assets/images/Janelle Grass_2.jpg';

export default [
  {
    name: 'Melissa Pizzo, MA, LPC',
    image: { src: melissaHeadshot, alt: 'Melissa Pizzo, Owner/Counselor' },
    phone: '970-823-2392',
    bio: [
      'From day to day, life presents many challenges that makes it hard to stay balanced and feel positive. I believe in the uniqueness and benefits of the therapeutic relationship and space to allow a person to feel supported and heard. I approach therapy from a strengths-based, narrative approach. Telling your story can be powerful and can allow for beginning steps to healing and I believe in the ability a person has to pull from his or her own strengths and experiences to start to feel better.',
      'I received my Masters in Clinical Mental Health Counseling and have worked in the reproductive and womens health field for 4 years in alternative, residential and clinic/hospital settings and in various other mental health settings for 10 years. I am a Licensed Professional Counselor and Nationally Certified Counselor. I have a background in treating adolescents, young adults, and adults who are struggling with depression, anxiety, transitional stages of life, relationships, a history of trauma, grief and loss and stress management. For the last few years I’ve specialized in reproductive and maternal mental health, both in prenatal and postpartum care.'
    ],
    psychTodayProfile: 'https://www.psychologytoday.com/profile/424493'
  },
  {
    name: 'Janelle Kramer, MA, LAC, LPC',
    image: { src: janelleHeadshot, alt: 'Janelle Kramer, Owner/Counselor' },
    phone: '720-773-1104',
    bio: [
      'My passion for emotionally supporting others is what guided me to becoming a therapist. Everybody’s emotional response to an event or interaction is valid. Part of my job is to support people in finding ways to cope with struggles that build connection, not drive isolation. You are the expert of your own life, and I can create a safe space for you to work through emotions and conversations that support your life values. I work with couples, individuals, and adult families from a trauma-informed attachment perspective, which means that my focus is to help foster connection while maintaining awareness of past traumas or hurts.',
      'I have a Masters degree in Couples and Family Therapy from University of Colorado Denver.  I am a Licensed Addictions Counselor, a Licensed Professional Counselor, and a Nationally Certified Counselor.  I specialize in working with people affected by addiction. In addition, I am skilled in working with reproductive and womens health, with people of various mental health complaints including depression and anxiety, and with those struggling with life transitions.'
    ],
    psychTodayProfile: 'https://www.psychologytoday.com/profile/430491'
  }
];
