import React from 'react';
import './style.css';

const Notice = () => (
  <div className='landing__notice'>
    <h2 className='notice'>
      <a
        className='text-white'
        href='http://voyagedenver.com/interview/meet-melissa-pizzo-janelle-kramer-interconnections-counseling-wheat-ridge/'
        target='_blank'
        rel='noopener noreferrer'
      >
        See us in <i>VoyageDenver Magazine</i>
      </a>
    </h2>
  </div>
);

export default Notice;
