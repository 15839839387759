import React from 'react';
import './style.css';

export const Copyright = () => (
  <div className='copyright'>
    <p>
      <span className='copyright__symbol' alt='copyright symbol'>
        ©
      </span>{' '}
      2018-2021 Interconnections Counseling, LLC.
      <br />
      Last Modified 12/11/2021
    </p>
  </div>
);

export default Copyright;
