import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';

const Application = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const root = document.getElementById('root');

if (root.hasChildNodes() === true) {
  ReactDOM.hydrate(Application, root);
} else {
  ReactDOM.render(Application, root);
}

registerServiceWorker();
