import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import MapGroup from '../MapGroup';
import Copyright from '../Copyright';
import Routes from '../Routes';
import './style.css';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: null,
    };
    this.getInnerWidth = this.getInnerWidth.bind(this);
  }

  getInnerWidth() {
    this.setState(() => ({ innerWidth: window.innerWidth }));
  }

  componentDidMount() {
    this.getInnerWidth();
    window.addEventListener('resize', this.getInnerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getInnerWidth);
  }

  render() {
    const { innerWidth } = this.state;
    const { location } = this.props;

    return (
      <div className='App'>
        <Header />
        <Routes innerWidth={innerWidth} />
        {location.pathname !== '/contact' && (
          <MapGroup innerWidth={innerWidth} />
        )}
        <Copyright />
      </div>
    );
  }
}

export default withRouter(App);
