import React from 'react';
import { CardDeck, Container } from 'reactstrap';
import InfoCard from '../InfoCard';
import './style.css';

export const cardData = [
  {
    title: 'Addictions',
    subtitle:
      'We work with people who want to explore decreasing their drug and alcohol use or are effected by the substance use of a loved one.',
  },
  {
    title: 'Relationship Counseling',
    subtitle:
      'We offer couples and adult family therapy with a specialty in coping with addictions and maternal mental health issues.',
  },
  {
    title: 'Maternal Mental Health',
    subtitle:
      'Our practice specializes in perinatal, postpartum, and reproductive mental healthcare.',
  },
];

export const InfoCardGroup = ({ style }) => (
  <Container fluid style={style}>
    <CardDeck className="col-tablet">
      {cardData.map(card => (
        <InfoCard {...card} key={card.title} />
      ))}
    </CardDeck>
  </Container>
);

export default InfoCardGroup;
