import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Homepage from '../Homepage';
import About from '../About';
import Contact from '../Contact';
import Insurances from '../Insurances';
import Resources from '../Resources';
import Fees from '../Fees';

export const Routes = ({innerWidth}) => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route path="/about" component={About} />
    <Route path="/contact" render={() => <Contact innerWidth={innerWidth} />} />
    <Route path="/insurances" component={Insurances} />
    <Route path="/fees" component={Fees} />
    <Route
      path="/resources"
      render={() => <Resources innerWidth={innerWidth} />}
    />
  </Switch>
);

export default Routes;
