import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import logoMedium from '../../assets/images/logo-medium.png';
import leafMedium from '../../assets/images/leaf-medium.png';
import './style.css';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(() => ({
      isOpen: !this.state.isOpen,
    }));
  }

  render() {
    const {isOpen} = this.state;

    return (
      <header className="header">
        <Navbar
          className="header__navbar header__navbar--scaled NavBar"
          color="white"
          light
          fixed="top">
          <Nav navbar>
            <NavItem className="header__brand-phone nav-link p-1 no-hover">
              {/* <a */}
              {/*   href="https://interconnectionscounseling.mytherabook.com/appointments/new" */}
              {/*   target="_blank" */}
              {/*   rel="noopener noreferrer"> */}
              {/*   Schedule An Appointment */}
              {/* </a>{' '} */}
              Call us today at{' '}
              <a href="tel:+19708232392" className="no-wrap">
                970-823-2392
              </a>{' '}
              or email us at{' '}
              <a
                href="mailto:info@interconnectionscounseling.com"
                aria-label="Email interconnections counseling">
                info@interconnectionscounseling.com
              </a>
            </NavItem>
          </Nav>
          <NavbarBrand href="/" className="header__brand-title mr-0 p-1">
            <img
              src={leafMedium}
              alt="Interconnections Counseling Logo"
              className="header__brand-logo"
            />
            Interconnections Counseling
          </NavbarBrand>
        </Navbar>
        <Navbar
          className="header__navbar header__navbar--margin-top NavBar nav-btn-pd"
          color="light"
          light
          expand="lg">
          <NavbarBrand href="/" className="p-0">
            <img
              src={logoMedium}
              alt="Interconnections Counseling Logo"
              className="header__logo"
            />
          </NavbarBrand>
          <NavbarToggler onClick={() => this.toggle()} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto header__nav" navbar>
              <NavItem>
                <NavLink className="nav-link text-center" to="/about">
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link text-center" to="/resources">
                  Resources
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-center">
                  Payments
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="text-center">
                    <NavLink className="nav-link text-center" to="/insurances">
                      Insurances
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem className="text-center">
                    <NavLink className="nav-link text-center" to="/fees">
                      Fees
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink className="nav-link text-center" to="/contact">
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
